import "@aws-amplify/ui-react/styles.css";
import "./loginArea.scss";
import {
  Authenticator,
  useAuthenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../aws-exports";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { I18n } from "aws-amplify";
import dict from "../../i18n.js";

// Set language and vocabularies
I18n.putVocabularies(dict);
I18n.setLanguage("pt-br");

Amplify.configure(awsExports);

function LoginArea({ signOut, user }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, from, navigate]);





  // Azure SSO Sign-In through federatedSignIn standard method (using aws-exports.js)
  const signInWithAzureStandardMethod = async () => {
    try {
      await Auth.federatedSignIn({ provider: "AzureAD" });
    } catch (error) {
      console.error("Error signing in with Azure: ", error);
    }
  };
 


  // Custom function to handle direct Azure SSO login via Cognito
  const signInWithAzure = () => { 
   
    try { 
      // Construct the OAuth URL
      const url = `https://isesurvey.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=38pohfaqj3jl81fp5djui0rket&response_type=code&scope=email+openid+phone&redirect_uri=https://survey2.0.ise.org.br/dashboard`;
  
      // Redirect the user directly to the Azure AD login page
      window.location.href = url;
    } catch (error) {
      console.error(error);
    }
  };
  
  
  
  

  
  return (
    <Authenticator
      components={{
        SignIn: {
          Footer() {
            return (
              <div className="azure-sso-button-wrapper">
              <input
                type="button"
                className={"azure-sso-button"}
                value="&nbsp;&nbsp;&nbsp;Entrar com sua conta Microsoft"
                onClick={signInWithAzure}
                style={{
                  backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg')",
                  backgroundSize: '8%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5% center',
                  paddingLeft: '40px'
                }}
              />   
              </div>
            );
          },
        },
      }}
    />
  );
  
}

export default LoginArea; 
