import AddOptions from "components/AddOptions/AddOptions";
import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import Checkbox from "components/Checkbox/checkbox";
import CloseButton from "components/CloseButton/closeButton";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import DropdownListField from "components/DropdownListField/dropdownListFIeld";
import Field from "components/Field/field";
import FormContainer from "components/FormContainer/formContainer";
import Layout from "components/Layout/layout";
import NPSfield from "components/NPSfield/NPSfield";
import PageTitle from "components/PageTitle/pageTitle";
import Rating from "components/Rating/Rating";
import { Archive } from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router";
import { addNewAlert } from "Services/alertsManagement";
import { PlusCircle } from "phosphor-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from "./editQuestion.module.scss";
import {
  archiveQuestionById,
  createQuestion,
  getQuestionById,
  updateQuestionById,
} from "./questionService";
import Loading from "components/Loading/loading";

const EditQuestion = () => {
  let { questionToEdit } = useParams();

  let pageTitle;

  pageTitle = questionToEdit == "new" ? "Criar Questão" : "Editar Questão";

  const [questionTitle, setQuestionTitle] = useState();

  const [questionHeader, setQuestionHeader] = useState({});

  const [questionType, setQuestionType] = useState();

  const [questionTypeOptionsItems, setQuestionTypeOptionsItems] = useState([]);
  const [questionNPSItems, setQuestionNPSItems] = useState({});

  const [questionTypeOptionsWeight, setQuestionTypeOptionsWeight] =
    useState(false);

  const [languages, setLanguages] = useState({});

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);

  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const [alerts, setAlerts] = useState([]);

  const [npsDisabled, setNPSDisabled] = useState(false);

  const [ordersUsing, setOrdersUsing] = useState(0);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  //Develop integration to API (backend) using useEffect to get questionToEdit and send a get request to API in order to retrieve question info

  const setChooseLanguages = (chooseLanguages) => {
    let _languages = { ...languages };

    if (chooseLanguages) {
      if (chooseLanguages.pt) {
        _languages.pt = true;
      }
      if (chooseLanguages.en) {
        _languages.en = true;
      }
      if (chooseLanguages.es) {
        _languages.es = true;
      }
      setLanguages(_languages);
    }
  };

  const setWeight = (alternatives) => {
    let weightFound = alternatives.find((item) => item.weight != null);
    weightFound
      ? setQuestionTypeOptionsWeight(true)
      : setQuestionTypeOptionsWeight(false);
  };

  const fetchData = () => {
    const getQuestionData = async () => {
      const questionData = await getQuestionById(questionToEdit);
      setNPSDisabled(true);
      setQuestionTitle(questionData.title);
      if ("ordersUsing" in questionData) {
        setOrdersUsing(parseInt(questionData.ordersUsing))
      } else {
        setOrdersUsing(0)
      }
      if (questionData.message) {
        navigate("/notFound");
      }
      setChooseLanguages(questionData.header);
      setQuestionHeader(questionData.header);
      setQuestionType(questionData.questionType);
      if (
        questionData.questionType == "single-choice" ||
        questionData.questionType == "multiple-choice"
      ) {
        setQuestionTypeOptionsItems(questionData.alternatives);
        setWeight(questionData.alternatives);
      }
      if (questionData.questionType == "nps") {
        setQuestionNPSItems(questionData.subject);
      }
      setLoading(false); 
    };
    questionToEdit != "new" ? getQuestionData() : setLoading(false);
  };

  const newQuestion = () => {
    pageTitle = questionToEdit == "new" ? "Criar Questão" : "Editar Questão";
    setQuestionTitle("");
    setQuestionHeader({ pt: null, en: null, es: null });
    setQuestionType(null);
    setQuestionTypeOptionsItems([""]);
    setQuestionNPSItems({ pt: null, en: null, es: null });
    setQuestionTypeOptionsWeight(false);
    setLanguages({ pt: null, en: null, es: null });
    setNPSDisabled(false);
    setOrdersUsing(0);
    setLoading(false);
  }

  const buildQuestionBody = (requestType) => {
    let questionBody = {
      title: questionTitle,
      header: questionHeader,
      questionType: questionType,
    };

    if (questionType == "multiple-choice" || questionType == "single-choice") {
      questionBody.alternatives = questionTypeOptionsItems;
    }

    if (questionType == "nps" && requestType == "POST") {
      questionBody.subject = questionNPSItems;
    }
    return questionBody;
  };

  const questionValidation = (requestType) => {
    let isValid = true;

    let languagesCount = Object.values(languages).length;

    let isQuestionTitleUndefined =
      questionTitle == undefined || questionTitle == "";

    if (isQuestionTitleUndefined || languagesCount == 0) {
      return (isValid = false);
    }

    if (Object.keys(questionHeader).length == 0) {
      return (isValid = false);
    }

    if (questionType == undefined) {
      return (isValid = false);
    }

    if (
      (languages.pt == true && !("pt" in questionHeader)) ||
      (languages.en == true && !("en" in questionHeader)) ||
      (languages.es == true && !("es" in questionHeader))
    ) {
      return (isValid = false);
    }

    if (questionType == "multiple-choice" || questionType == "single-choice") {
      if (questionTypeOptionsItems.length == 0) {
        return (isValid = false);
      } else {
        questionTypeOptionsItems.forEach((item) => {
          let _item = { ...item };
          delete _item["index"];
          delete _item["weight"];

          if (
            (languages.pt == true && questionTypeOptionsItems.pt == "") ||
            (languages.pt == true && !item["pt"]) ||
            (languages.en == true && questionTypeOptionsItems.en == "") ||
            (languages.en == true && !item["en"]) ||
            (languages.es == true && questionTypeOptionsItems.es == "") ||
            (languages.es == true && !item["es"]) ||
            (questionTypeOptionsWeight == true && !item.weight)
          ) {
            return (isValid = false);
          }
        });
      }
    }

    if (questionType == "nps" && requestType == "POST") {
      if (languagesCount != Object.keys(questionNPSItems).length) {
        return (isValid = false);
      }
    }

    return isValid;
  };

  const updateQuestion = async () => {
    const isValid = await questionValidation("PATCH");
    let updateAlertList = "";

    if (isValid == true) {
      let questionBody = buildQuestionBody("PATCH");
      setLoading(true);
      updateQuestionById(questionToEdit, questionBody).then(
        (editedQuestion) => {
          if (editedQuestion.status == 200) {
            setLoading(false);
            let message =
              "Questão '" + questionTitle + "' atualizada com sucesso!";
            updateAlertList = addNewAlert([], { message, type: "success" });
          } else {
            setLoading(false);
            let messageError = editedQuestion.body.message;
            updateAlertList = addNewAlert([], {
              messageError,
              type: "danger",
            });
          }
          setAlerts(updateAlertList);
          setLoading(false);
        }
      );
    } else {
      let message =
        "Preencha todos os campos obrigátorios para salvar a questão!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
    }
  };

  const createNewQuestion = async () => {
    const isValid = await questionValidation("POST");
    let updateAlertList = "";

    if (isValid == true) {
      setLoading(true);
      let questionBody = buildQuestionBody("POST");
      createQuestion(questionBody).then((newQuestion) => {
        if (newQuestion.status == 200) {
          setLoading(false);
          navigate("/question/" + newQuestion.body["id"]);
          let message = "Questão '" + questionTitle + "' criada com sucesso!";
          updateAlertList = addNewAlert([], { message, type: "success" });
        } else {
          setLoading(false);
          let messageError = newQuestion.body.message;
          updateAlertList = addNewAlert([], {
            messageError,
            type: "danger",
          });
        }
        setAlerts(updateAlertList);
        setLoading(false);
      });
    } else {
      let message =
        "Preencha todos os campos obrigátorios para salvar a questão!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
    }
  };
  const removeLanguageAnswers = (lang) => {
    let _questionHeader = { ...questionHeader };
    delete _questionHeader[lang];
    setQuestionHeader(_questionHeader);

    let _languages = { ...languages };
    delete _languages[lang];
    setLanguages(_languages);

    if (questionType == "single-choice" || questionType == "multiple-choice") {
      let _questionTypeOptionsItems = [...questionTypeOptionsItems];
      _questionTypeOptionsItems.forEach((items) => {
        delete items[lang];
      });
      setQuestionTypeOptionsItems(_questionTypeOptionsItems);
    }

    if (questionType == "nps") {
      let _questionNPSItems = { ...questionNPSItems };
      delete _questionNPSItems[lang];
      setQuestionNPSItems(_questionNPSItems);
    }
  };

  const archiveQuestion = () => {
    archiveQuestionById(questionToEdit).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Questão " + questionTitle + " arquivada com sucesso!";
        navigate("/archivedQuestions/");
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderContent = () => {

    switch (loading) {  
      case true:
        return (
          <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
        );
      case false:
        if (capabilities.includes("cadastros")) {
          return(
            <>
            <div className={styles.buttonsContainer} >
            <Button
              className={styles.createQuestionButton + " " + "primaryOrange"}
              onClick={() => {
                navigate("/question/new");
                window.location.reload();
              }}
            >
              <PlusCircle size={22} className={styles.addIcon} />
              <span style={{ paddingBottom: "3px" }}>Criar Questão</span>
            </Button>
          </div>
          {alerts.map((i, k) => {
            return <Alert message={i.message} type={i.type} style={{ width: '90%' }} />;
          })}
          {ordersUsing > 0 &&
            <Alert message={`Esta questão está sendo utilizada em ${ordersUsing} pedidos.`} type={"warning"} />
          }
          <FormContainer>
            <div className={styles.fieldContainer}>
              <label for={"questionTitle"}>
                Título<span style={{ color: "red" }}>*</span>
              </label>
              <Field
                placeholder={"Título da questão"}
                name={"questionTitle"}
                id={"questionTitle"}
                defaultValue={questionTitle}
                onChange={(event) => setQuestionTitle(event.target.value)}
              />
            </div>
            <div className={styles.fieldContainer}>
              <label for={"languages"}>
                Idiomas Disponíveis<span style={{ color: "red" }}>*</span>
              </label>
              <div id={"languages"} className={styles.languagesContainer}>
                <div>
                  <Checkbox
                    id={"pt"}
                    checked={languages.pt}
                    onClick={(event) => {
                      let _languages = { ...languages };
                      if (event.target.checked == false) {
                        removeLanguageAnswers("pt");
                      } else {
                        _languages.pt = event.target.checked;
                        setLanguages(_languages);
                      }
                    }}
                  />
                  <label for={"pt"}>Português</label>
                </div>
                <div>
                  <Checkbox
                    id={"en"}
                    checked={languages.en}
                    onClick={(event) => {
                      let _languages = { ...languages };
                      _languages.en = event.target.checked;
                      if (event.target.checked == false) {
                        removeLanguageAnswers("en");
                      } else {
                        _languages.en = event.target.checked;
                        setLanguages(_languages);
                      }
                    }}
                  />
                  <label for={"en"}>Inglês</label>
                </div>
                <div>
                  <Checkbox
                    id={"es"}
                    checked={languages.es}
                    onClick={(event) => {
                      let _languages = { ...languages };
                      _languages.es = event.target.checked;
                      if (event.target.checked == false) {
                        removeLanguageAnswers("es");
                      } else {
                        _languages.es = event.target.checked;
                        setLanguages(_languages);
                      }
                    }}
                  />
                  <label for={"es"}>Espanhol</label>
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              {languages.pt ? (
                <div className={styles.fieldContainer}>
                  <label for={"questionHeader"}>
                    Cabeçalho PT<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Cabeçalho da questão em PT"}
                    name={"questionHeaderPT"}
                    id={"questionHeaderPT"}
                    defaultValue={questionHeader.pt}
                    onChange={(event) => {
                      let _questionHeader = { ...questionHeader };
                      event.target.value == ""
                        ? delete _questionHeader["pt"]
                        : (_questionHeader.pt = event.target.value);
                      setQuestionHeader(_questionHeader);
                    }}
                  />
                </div>
              ) : null}
              {languages.en ? (
                <div className={styles.fieldContainer}>
                  <label for={"questionHeader"}>
                    Cabeçalho EN<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Cabeçalho da questão em EN"}
                    name={"questionHeaderEN"}
                    id={"questionHeaderEN"}
                    defaultValue={questionHeader.en}
                    onChange={(event) => {
                      let _questionHeader = { ...questionHeader };
                      event.target.value == ""
                        ? delete _questionHeader["en"]
                        : (_questionHeader.en = event.target.value);
                      setQuestionHeader(_questionHeader);
                    }}
                  />
                </div>
              ) : null}
              {languages.es ? (
                <div className={styles.fieldContainer}>
                  <label for={"questionHeader"}>
                    Cabeçalho ES<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Cabeçalho da questão em ES"}
                    name={"questionHeaderES"}
                    id={"questionHeaderES"}
                    defaultValue={questionHeader.es}
                    onChange={(event) => {
                      let _questionHeader = { ...questionHeader };
                      event.target.value == ""
                        ? delete _questionHeader["es"]
                        : (_questionHeader.es = event.target.value);
                      setQuestionHeader(_questionHeader);
                    }}
                  />
                </div>
              ) : null}
            </div>
            {languages.pt || languages.en || languages.es ? (
              <>
                <div
                  className={styles.fieldContainer}
                  style={{ display: "flex" }}
                >
                  <div className={styles.columnsContainer}>
                    <div className={styles.col3}>
                      <label for={"questionType"}>
                        Tipo de questão
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div style={{ paddingTop: "10px" }}>
                        <DropdownListField
                          disabled={questionToEdit == "new" ? false : true}
                          defaultValue={questionType}
                          style={{ width: "80%" }}
                          placeholder={"Escolha o tipo da questão"}
                          onChange={(value) => {
                            setQuestionType(value);
                          }}
                          listOfItems={[
                            { value: "rating-scale", label: "Classificação" },
                            { value: "text-area", label: "Dissertativa" },
                            { value: "single-choice", label: "Alternativa" },
                            {
                              value: "multiple-choice",
                              label: "Múltipla escolha",
                            },
                            {
                              value: "nps",
                              label: "NPS",
                            },
                            {
                              value: "label",
                              label: "Cabeçalho",
                            },
                          ]}
                        />
                      </div>
                    </div>

                    <div className={styles.col8}>
                      {(() => {
                        switch (questionType) {
                          case "rating-scale":
                            return (
                              <div>
                                <label for={"rating"}>Classificação</label>
                                <div>
                                  <Rating />
                                </div>
                              </div>
                            );
                          case "single-choice":
                            return (
                              <div>
                                <div style={{ display: "flex" }}>
                                  <label for={"rating"}>Alternativas</label>
                                  &nbsp;&nbsp;
                                  <div style={{ marginLeft: "auto" }}>
                                    <label
                                      for={"peso"}
                                      style={{ fontSize: 14 }}
                                    >
                                      Habilitar peso
                                    </label>
                                    <Checkbox
                                      checked={questionTypeOptionsWeight}
                                      className={styles.checkboxHabilitarPeso}
                                      onClick={() => {
                                        if (
                                          questionTypeOptionsWeight == false
                                        ) {
                                          setQuestionTypeOptionsWeight(true);
                                        } else {
                                          setQuestionTypeOptionsWeight(false);
                                          let _questionTypeOptionsItems = [
                                            ...questionTypeOptionsItems,
                                          ];
                                          _questionTypeOptionsItems.forEach(
                                            (items) => {
                                              delete items["weight"];
                                            }
                                          );
                                          setQuestionTypeOptionsItems(
                                            _questionTypeOptionsItems
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <AddOptions
                                    includeWeight={questionTypeOptionsWeight}
                                    list={questionTypeOptionsItems}
                                    setList={setQuestionTypeOptionsItems}
                                    languages={languages}
                                  />
                                </div>
                              </div>
                            );
                          case "multiple-choice":
                            return (
                              <div>
                                <div style={{ display: "flex" }}>
                                  <label for={"rating"}>Múltipla escolha</label>
                                  &nbsp;&nbsp;
                                  <div style={{ marginLeft: "auto" }}>
                                    <label
                                      for={"peso"}
                                      style={{ fontSize: 14 }}
                                    >
                                      Habilitar peso
                                    </label>
                                    <Checkbox
                                      className={styles.checkboxHabilitarPeso}
                                      onClick={() => {
                                        if (
                                          questionTypeOptionsWeight == false
                                        ) {
                                          setQuestionTypeOptionsWeight(true);
                                        } else {
                                          setQuestionTypeOptionsWeight(false);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <AddOptions
                                    includeWeight={questionTypeOptionsWeight}
                                    list={questionTypeOptionsItems}
                                    setList={setQuestionTypeOptionsItems}
                                    languages={languages}
                                  />
                                </div>
                              </div>
                            );
                          case "nps":
                            return (
                              <div className={styles.npsContainer}>
                                <NPSfield
                                  languages={languages}
                                  npsItems={questionNPSItems}
                                  setNPSItems={setQuestionNPSItems}
                                  disabled={npsDisabled}
                                ></NPSfield>
                              </div>
                            );

                          default:
                            return null;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </FormContainer>
          {ordersUsing < 1 &&
            <div className={styles.buttonsContainer}>
              <div>
                <Button
                  className={styles.saveButton + " " + "primaryOrange"}
                  onClick={() => {
                    if (questionToEdit == "new") {
                      createNewQuestion();
                    } else {
                      updateQuestion();
                    }
                  }}
                >
                  Salvar
                </Button>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  className={styles.cancelButton + " " + "secondaryOrange"}
                  onClick={() => {
                    setClosingConfirmationModal(true);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          }
        </>
          )} else {
            return (
              <div className={styles.error}>
              <center>
                <Alert
                  type={"danger"}
                  message={
                    "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                  }
                />
              </center>
            </div>
            );
          }
      default:
        return null;
    }
  }
          

  return (
    <Layout>
      <div className={styles.pageHeader}>
            <PageTitle>{pageTitle}</PageTitle>
            <div className={styles.closeButton}>
              {questionToEdit != "new" ? (
                <ConfirmationModal
                  id={"archiveQuestion"}
                  iconComponent={
                    <Button title={"Arquivar"} className={styles.archiveButton}>
                      <Archive size={20} className={styles.archiveIcon} />
                    </Button>
                  }
                  title={"Deseja realmente arquivar esta questão?"}
                  onClick={() => setArchiveConfirmationModal(true)}
                  open={archiveConfirmationModal}
                  onOk={archiveQuestion}
                  onCancel={() => setArchiveConfirmationModal(false)}
                />
              ) : null}
              <ConfirmationModal
                id={"closeQuestion"}
                iconComponent={<CloseButton />}
                title={"Deseja sair?"}
                onClick={() => setClosingConfirmationModal(true)}
                open={closingConfirmationModal}
                onOk={() => navigate("/questions")}
                onCancel={() => setClosingConfirmationModal(false)}
              />
            </div>
          </div>
          
      {renderContent()}
    </Layout>
  );
};

export default EditQuestion;
